import './styles/importer.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-virtualized/styles.css';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';
import fi from 'antd/lib/locale/fi_FI';
import sv from 'antd/lib/locale/sv_SE';
import en from 'antd/lib/locale/en_US';
import de from 'antd/lib/locale/de_DE';
import no from 'antd/lib/locale/nb_NO';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme/theme';

import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { Route, Switch } from 'react-router-dom';

import Navigation from './components/navigation/Navigation';
import ErrorBoundary from './ErrorBoundary';

import Auth, { SaamContainer, NiamContainer } from './containers/company/Auth';
import Base from './containers/company/Base';
import CompanyBase from './containers/company/CompanyBase';
import NotFound from './pages/NotFound';
import ErrorPage from './pages/ErrorPage';

const LoginPage = lazy(() => import('./containers/company/LoginPage'));
const FirstLogin = lazy(() => import('./containers/company/FirstLogin'));

const CreateRegister = lazy(() => import('./containers/company/registers/CreateRegister'));
const RegisterTrash = lazy(() => import('./pages/company/registers/RegisterTrash'));
const AddIdentifiers = lazy(() => import('./pages/company/registers/AddIdentifiers'));
const AddDatatypes = lazy(() => import('./pages/company/AddDatatypes'));
const EditHeaders = lazy(() => import('./pages/company/EditRegisterHeaders'));
const ArrangeHeaders = lazy(() => import('./containers/company/registers/ArrangeHeaders'));
const ChangeRequests = lazy(() => import('./containers/company/registers/ChangeRequests'));
const ViewRecords = lazy(() => import('./containers/company/registers/ViewRecords'));
const CompanyEditRecord = lazy(() => import('./containers/company/registers/CompanyEditRecord'));
const Registers = lazy(() => import('./pages/company/registers'));
const HupOrder = lazy(() => import('./pages/company/HupOrder'));
const PhoneBook = lazy(() => import('./pages/company/PhoneBook'));

const FaqPage = lazy(() => import('./containers/company/registers/FAQpage'));
const SettingsContainer = lazy(() =>
  import('./pages/company/registers/settings/SettingsContainer')
);
const PreviewPrivacyPolicy = lazy(() =>
  import('./pages/company/registers/settings/PreviewPrivacyPolicy')
);

const TargetGroups = lazy(() => import('./containers/company/targeting/TargetGroups'));

const Administration = lazy(() => import('./pages/administration/admin'));
const CompanyTrash = lazy(() => import('./pages/administration/CompanyTrash'));
const CompanyUserManagement = lazy(() => import('./pages/administration/CompanyUserManagement'));
const ViewLogs = lazy(() => import('./containers/company/ViewLogs'));
const Scythe = lazy(() => import('./pages/administration/Scythe'));

const CustomerAuth = lazy(() => import('./containers/customer/CustomerAuth'));
const CustomerViewData = lazy(() => import('./pages/customer/CustomerViewData'));
const CustomerMagicLinkAuthentication = lazy(() =>
  import('./pages/customer/CustomerMagicLinkAuthentication')
);
const CustomerNationalIdentityAuthentication = lazy(() =>
  import('./pages/customer/CustomerNationalIdentityAuthentication')
);

function WaitingComponent(Component) {
  return props => (
    <Suspense
      fallback={
        <div>
          <p className="text-center">
            <i className="fa fa-circle-o-notch fa-spin fa-fw fa-4x"></i>
          </p>
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
}

const App = ({ store, history, currentLanguage }) => {
  const langs = {
    fi,
    sv,
    en,
    no,
    de
  };
  return (
    <ConfigProvider locale={langs[currentLanguage]}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary store={store}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <div>
                <Navigation history={history} />

                <Switch>
                  <Route
                    exact
                    path="/identify/:company"
                    component={WaitingComponent(CustomerAuth)}
                  />
                  <Route
                    exact
                    path="/privacypolicy/:company/:lang"
                    component={WaitingComponent(PreviewPrivacyPolicy)}
                  />
                  <Route
                    exact
                    path="/sso/:hash"
                    component={WaitingComponent(CustomerMagicLinkAuthentication)}
                  />
                  <Route
                    exact
                    path="/passwordless-login/:hash"
                    component={WaitingComponent(CustomerMagicLinkAuthentication)}
                  />
                  <Route
                    exact
                    path="/national-id-login"
                    component={WaitingComponent(CustomerNationalIdentityAuthentication)}
                  />
                  <Route exact path="/viewdata" component={WaitingComponent(CustomerViewData)} />
                  <Route exact path="/faq" component={WaitingComponent(FaqPage)} />
                  <Route path="/company">
                    <CompanyBase>
                      <Switch>
                        <Route
                          exact
                          path="/company/registers"
                          component={WaitingComponent(Registers)}
                        />
                        <Route
                          exact
                          path="/company/registers/new"
                          component={WaitingComponent(CreateRegister)}
                        />
                        <Route
                          exact
                          path="/company/registers/trash"
                          component={WaitingComponent(RegisterTrash)}
                        />
                        <Route
                          exact
                          path="/company/registers/add-identifiers/:id"
                          component={WaitingComponent(AddIdentifiers)}
                        />
                        <Route
                          exact
                          path="/company/registers/add-datatypes/:id"
                          component={WaitingComponent(AddDatatypes)}
                        />
                        <Route
                          exact
                          path="/company/registers/edit-headers/:id"
                          component={WaitingComponent(EditHeaders)}
                        />
                        <Route
                          exact
                          path="/company/registers/arrange-columns/:id"
                          component={WaitingComponent(ArrangeHeaders)}
                        />

                        <Route
                          exact
                          path="/company/registers/changerequests/"
                          component={WaitingComponent(ChangeRequests)}
                        />
                        <Route
                          exact
                          path="/company/registers/:register/viewRecords"
                          component={WaitingComponent(ViewRecords)}
                        />
                        <Route
                          exact
                          path="/company/registers/editrecord/:id"
                          component={WaitingComponent(CompanyEditRecord)}
                        />

                        <Route
                          exact
                          path="/company/targetgroups"
                          component={WaitingComponent(TargetGroups)}
                        />
                        <Route
                          exact
                          path="/company/services"
                          component={WaitingComponent(HupOrder)}
                        />
                        <Route
                          exact
                          path="/company/services/:product/:register"
                          component={WaitingComponent(HupOrder)}
                        />
                        <Route
                          exact
                          path="/company/phonebook"
                          component={WaitingComponent(PhoneBook)}
                        />

                        <Route
                          exact
                          path="/company/welcome"
                          component={WaitingComponent(FirstLogin)}
                        />
                      </Switch>
                    </CompanyBase>
                  </Route>
                  <Route path="/administration">
                    <Base>
                      <Switch>
                        <Route
                          exact
                          path="/administration"
                          component={WaitingComponent(Administration)}
                        />
                        <Route
                          exact
                          path="/administration/trash"
                          component={WaitingComponent(CompanyTrash)}
                        />
                        <Route
                          exact
                          path="/administration/:company/usermanagement"
                          component={WaitingComponent(CompanyUserManagement)}
                        />
                        <Route
                          exact
                          path="/administration/:company/viewLogs"
                          component={WaitingComponent(ViewLogs)}
                        />
                        <Route
                          exact
                          path="/administration/:company/registers"
                          component={WaitingComponent(Registers)}
                        />
                        <Route
                          exact
                          path="/administration/:company/changerequests"
                          component={WaitingComponent(ChangeRequests)}
                        />

                        <Route
                          exact
                          path="/administration/scythe"
                          component={WaitingComponent(Scythe)}
                        />
                      </Switch>
                    </Base>
                  </Route>
                  <Route path="/settings">
                    <Base>
                      <Switch>
                        <Route
                          exact
                          path="/settings/privacypolicy/preview/:id/:lang"
                          component={WaitingComponent(PreviewPrivacyPolicy)}
                        />
                        <Route
                          exact
                          path="/settings"
                          component={WaitingComponent(SettingsContainer)}
                        />
                      </Switch>
                    </Base>
                  </Route>
                  <Route path="/login" component={WaitingComponent(LoginPage)} />
                  <Route path="/error/:reason" component={ErrorPage} />
                  <Route exact path="/:parameters" component={Auth} />
                  <Route exact path="/saamcb/:code" component={SaamContainer} />
                  <Route exact path="/niamcb/:code/:state" component={NiamContainer} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </div>
            </ConnectedRouter>
          </Provider>
        </ErrorBoundary>
      </ThemeProvider>
    </ConfigProvider>
  );
};
const mapStateToProps = (state, ownProps) => ({
  currentLanguage: state.langState.currentLanguage
});
export default connect(mapStateToProps)(App);
