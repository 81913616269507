import React from 'react';

export default class Base extends React.Component {
  componentWillReceiveProps(nextProps) {
    // Check nextProps to see if location changed, if so check for login.
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.props.isLoggedIn();
    }
  }

  render() {
    return <div className="container-fluid">{this.props.children}</div>;
  }
}
