import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/sv';
import 'moment/locale/de';


import isEmpty from 'lodash/isEmpty';
import App from './App';
import { configureStore, history } from './store/configureStore';
import { loadContentStrings } from './actions/language-actions';

import React from 'react';
import ReactDOM from 'react-dom';

if (!window.Promise) {
  window.Promise = Promise;
}



const DEFAULTLANGUAGE = 'fi';
const store = configureStore();

if (isEmpty(localStorage.getItem('gepardi-userlang'))) {
  axios
    .get('/api/country')
    .then(res => {
      if (res.data === 'fi' || res.data === 'sv') {
        const userLang = res.data;
        moment.locale(userLang);
        store.dispatch(loadContentStrings(userLang));
      }
    })
    .catch(err => {
     //console.log(err);
      moment.locale(DEFAULTLANGUAGE);
      store.dispatch(loadContentStrings(DEFAULTLANGUAGE));
    });
} else {
  let userLang = localStorage.getItem('gepardi-userlang');
  if (userLang !== 'fi' && userLang !== 'sv' && userLang !== 'en' && userLang !== 'de')
    userLang = DEFAULTLANGUAGE;
  moment.locale(userLang);
  store.dispatch(loadContentStrings(userLang));
}

ReactDOM.render(<App store={store} history={history} />, document.getElementById('app'));
