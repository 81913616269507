import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import get from 'lodash/get';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  backToIdentificationLink = nameInUrl => {
    const { t } = this.props;
    if (nameInUrl) {
      return (
        <div className="row u-p-y-2 u-no-pt">
          <Link to={`/identify/${nameInUrl}`} className="pull-right u-m-x-2">
            {t('Customer.Pages.ErrorPage.ToFrontPage')}
          </Link>
        </div>
      );
    }
    return null;
  };

  reasonToText = reason => {
    const { t, dt } = this.props;
    switch (reason) {
      case 'passwordless-login':
        return dt(
          'Customer.Pages.ErrorPage.PasswordlessLogin',
          t('Company.Toasts.SomethingWentWrong')
        );
      case 'notAuthenticated':
        return dt(
          'Customer.Pages.ErrorPage.NotAuthenticated',
          t('Company.Toasts.SomethingWentWrong')
        );
      case 'national-id-login':
        return dt(
          'Customer.Pages.ErrorPage.NationalIdLogin',
          t('Company.Toasts.SomethingWentWrong')
        );
      case 'NoRecords':
        return dt('Customer.Pages.ErrorPage.NoRecords', t('Company.Toasts.SomethingWentWrong'));
      default:
        return t('Company.Toasts.SomethingWentWrong');
    }
  };

  render() {
    const { match } = this.props;
    const nameInUrl =
      window.location.hash.indexOf('?returnLink=') >= 0
        ? window.location.hash.substring(window.location.hash.indexOf('?returnLink=') + 12)
        : '';
    let msg;

    if (match && match.url.includes('/error/')) {
      const { reason } = match.params;
      msg = this.reasonToText(reason);
    } else if (this.state.hasError) {
      msg = this.reasonToText(undefined);
    }
    return (
      <div className="row main-content-area">
        <div className="row u-m-y-1 u-no-mt">
          <div className="c-jumbotron">
            <div className="row u-p-y-6 u-no-pt u-p-x-3">
              <h1 className="text-center">{msg}</h1>
            </div>
            {this.backToIdentificationLink(nameInUrl)}
          </div>
        </div>
      </div>
    );
  }
}

// Maps state from store to props
const mapStateToProps = state => ({
  t: phrase => get(state.langState.contentStrings, phrase, ''),
  dt: (phrase, defaultTranslation) => {
    const translation = get(state.langState.contentStrings, phrase, phrase);
    return !translation || translation === phrase ? defaultTranslation : translation;
  }
});

export default connect(mapStateToProps)(ErrorBoundary);
