import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// Reducers
import registerReducer from './register-reducer';
import companyReducer from './company-reducer';
import companyUsermanagementReducer from './company-usermanagement-reducer';
import subscriptionReducer from './subscription-reducer';
import langReducer from './language-reducer';
import customerReducer from './customer-reducer';
import userReducer from './user-reducer';
import logReducer from './log-reducer';
import adminReducer from './admin-reducer';
import targetGroupReducer from './target-group-reducer';
import reportReducer from './report-reducer';
import orderReducer from './order-reducer';

// Combine Reducers
const reducers = combineReducers({
  registerState: registerReducer,
  companyState: companyReducer,
  companyUsermanagementState: companyUsermanagementReducer,
  subscriptionState: subscriptionReducer,
  langState: langReducer,
  customerState: customerReducer,
  userState: userReducer,
  logState: logReducer,
  adminState: adminReducer,
  targetGroupState: targetGroupReducer,
  reportState: reportReducer,
  orderState: orderReducer,
  // https://github.com/ReactTraining/react-router/tree/master/packages/react-router-redux
  router: routerReducer
});

export default reducers;
