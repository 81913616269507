import * as types from '../actions/action-types';

const initialState = {
  termsAndConditions: [],
  faq: []
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADMIN_GET_TAC_SUCCESS:
      return Object.assign({}, state, { termsAndConditions: action.data });
    case types.ADMIN_ADD_TAC_SUCCESS:
      return Object.assign({}, state, {
        termsAndConditions: [...state.termsAndConditions, action.data]
      });
    case types.ADMIN_DELETE_TAC_SUCCESS:
      return Object.assign({}, state, {
        termsAndConditions: state.termsAndConditions.filter(value => value.id !== action.data.id)
      });
    case types.ADMIN_UPDATE_TAC_SUCCESS:
      return Object.assign({}, state, {
        termsAndConditions: state.termsAndConditions.map(value => {
          let newValue = value;
          if (value.id === action.data[0].id) {
            newValue = action.data[0];
          }
          return newValue;
        })
      });

    case types.ADMIN_GET_FAQ_SUCCESS:
      return Object.assign({}, state, { faq: action.data });

    default:
      // need this for default case
      return state;
  }
};

export default adminReducer;
