import filter from 'lodash/filter';
import * as types from '../actions/action-types';

const initialState = {
  companies: [],
  changeRequests: [],
  isFetching: false
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPANIES_SUCCESS:
      return Object.assign({}, state, { companies: action.companies });

    case types.CREATE_COMPANY_SUCCESS:
      return Object.assign({}, state, { companies: [...state.companies, action.company] });

    case types.UPDATE_COMPANY_SUCCESS: {
      const updatedCompaniesList = state.companies.map(value => {
        const newValue = value;
        if (newValue.id === action.data.id) {
          newValue.name = action.data.name;
          newValue.nameInUrl = action.data.nameInUrl;
          newValue.businessId = action.data.businessId;
          newValue.description = action.data.description;
        }
        return newValue;
      });
      return Object.assign({}, state, { companies: updatedCompaniesList });
    }

    case types.DELETE_COMPANY_SUCCESS: {
      const companiesWithoutDeleted = filter(
        state.companies,
        company => company.id !== action.data.company.id
      );
      return Object.assign({}, state, { companies: companiesWithoutDeleted });
    }

    case types.RESTORE_COMPANY_SUCCESS: {
      const companiesWithoutRestored = filter(
        state.companies,
        company => company.id !== action.data.company.id
      );
      return Object.assign({}, state, { companies: companiesWithoutRestored });
    }

    default:
      // need this for default case
      return state;
  }
};

export default companyReducer;
