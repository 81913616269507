import filter from 'lodash/filter';
import * as types from '../actions/action-types';

const initialState = {
  users: [],
  user: '',
  usersLandingPage: '',
  userForNavi: {},
  tac: {}, // Terms and conditions
  faq: []
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      return Object.assign({}, state, { users: action.users });

    case types.CREATE_USER_SUCCESS:
      return Object.assign({}, state, { users: [...(state.users + action.user)] });

    case types.UPDATE_USER_SUCCESS: {
      let updatedUsersList = state.users;
      updatedUsersList = updatedUsersList.map(value => {
        const newValue = value;
        if (value.id === action.user.id) {
          newValue.name = action.user.name;
          newValue.company = action.user.company;
          newValue.accessRole = action.user.accessRole;
        }
        return newValue;
      });
      return Object.assign({}, state, { users: updatedUsersList });
    }

    case types.DELETE_USER_SUCCESS: {
      // Use lodash to create a new user array without the user we want to remove
      const newUsers = filter(state.users, user => user.id !== action.userId);
      return Object.assign({}, state, { users: newUsers });
    }

    case types.USER_AUTHENTICATION_SUCCESS:
      return Object.assign({}, state, {
        user: action.data.data.token,
        usersLandingPage: action.data.data.usersLandingPage
      });

    case types.USER_LOGOUT_SUCCESS:
      return Object.assign({}, state, { ...initialState });

    case types.USER_UPDATE_SUCCESS:
      return Object.assign({}, state, { userForNavi: action.data.userForNavi });

    case types.USER_AUTHENTICATION_TEST_SUCCESS:
      return Object.assign({}, state, {
        user: action.data.data.token,
        usersLandingPage: action.data.data.usersLandingPage,
        userForNavi: action.data.data.userForNavi
      });

    case types.GET_USER_FOR_NAVI_SUCCESS:
      return Object.assign({}, state, { userForNavi: action.user });

    case types.GET_TAC_FOR_FIRST_LOGIN_SUCCESS:
      return Object.assign({}, state, { tac: action.data });

    case types.GET_FAQS_SUCCESS:
      return Object.assign({}, state, { faq: action.data });

    default:
      // need this for default case
      return state;
  }
};

export default userReducer;
