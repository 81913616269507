import get from 'lodash/get';

import * as types from '../actions/action-types';
import getContentStrings from '../api/language-api';
import { fetchFaq, fetchTac } from './auth-actions';

export const loadLanguagesSuccess = (contentStrings, currentLanguage) => {
  document.title = get(contentStrings, 'Site.Title');
  return {
    type: types.GET_LANGUAGES_SUCCESS,
    contentStrings,
    currentLanguage
  };
};
export const loadContentStrings = lang => dispatch =>
  getContentStrings(lang)
    .then(contentStrings => {
      dispatch(loadLanguagesSuccess(contentStrings, lang));
      dispatch(fetchTac(lang));
      dispatch(fetchFaq(lang));
    })
    .catch(error => {
      throw error;
    });
export const changeLanguage = currentLanguage => {
  return dispatch =>
    getContentStrings(currentLanguage)
      .then(contentStrings => {
        localStorage.setItem('gepardi-userlang', currentLanguage);
        dispatch(loadLanguagesSuccess(contentStrings, currentLanguage));
        dispatch(fetchTac(currentLanguage));
        dispatch(fetchFaq(currentLanguage));
      })
      .catch(error => {
        throw error;
      });
};
export const deleteRegisterSuccess = registerId => ({
  type: types.DELETE_REGISTER_SUCCESS,
  registerId
});
