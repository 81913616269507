import React from 'react';
import { isLoggedIn } from '../actions/auth-actions';

export default class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentStrings: {}
    };
  }

  componentWillMount() {
    isLoggedIn();
  }

  render() {
    return <div></div>;
  }
}
