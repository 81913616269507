import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

import * as authActions from '../../actions/auth-actions';
import Auth from '../../pages/Auth';
import Saam from '../../pages/Saam';
import Niam from '../../pages/Niam';

export const mapStateToProps = (state, ownProps) => ({
  isLoggedIn: authActions.isLoggedIn,
  t: phrase => get(state.langState.contentStrings, phrase, ''),
  dt: (phrase, defaultTranslation) => {
    const translation = get(state.langState.contentStrings, phrase, phrase);
    return translation === phrase ? defaultTranslation : translation;
  }
});

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(authActions, dispatch)
});

const AuthContainer = connect(mapStateToProps, mapDispatchToProps)(Auth);

export const SaamContainer = connect(mapStateToProps, mapDispatchToProps)(Saam);
export const NiamContainer = connect(mapStateToProps, mapDispatchToProps)(Niam);

// NOTE Use of withRouter due to https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md#quick-solution
export default withRouter(AuthContainer);
