/**
 * Utilities
 */

// Returns true if string is truthy (not null or undefined etc.)
// and contains something else than only whitespace; otherwise false
var stringIsNotNullOrEmpty = str => {
  return str && /\S/.test(str);
};

// Returns true if string is falsy (null, undefined etc.)
// or only contains whitespace; otherwise false
var stringIsNullOrEmpty = str => {
  return !stringIsNotNullOrEmpty(str);
};

const swedishYtunnusFormat = /^\d{10}$/;
const isSwedishBusinessId = str => swedishYtunnusFormat.test(str);

// Returns true if string is truthy and matches y-tunnus/Business ID format: [N]NNNNNN-C
// N = number 0-9 (first N is optional), C is checksum
var isValidBusinessId = str => {
  // Matches the Swedish format
  if (isSwedishBusinessId(str)) {
    return true;
  }
  if (!str || !/^[0-9]{6,7}-[0-9]$/.test(str)) {
    return false;
  }
  if (str.length == 8) str = '0' + str;

  const checksum = parseInt(str.charAt(8));
  const weights = [7, 9, 10, 5, 8, 4, 2];
  var weighedSum = 0;
  for (var i = 0; i < weights.length; i++) {
    weighedSum += parseInt(str.charAt(i)) * weights[i];
  }
  const modulus = weighedSum % 11;
  if (modulus == 1) return false;
  if (modulus == 0) return checksum == 0;
  return 11 - modulus == checksum;
};

var isNotValidBusinessId = str => {
  return !isValidBusinessId(str);
};

var generateCompanyNameInURL = name => {
  return name
    .replace(/\s/g, '-') // replace whitespace with "-"
    .replace('å', 'a')
    .replace('Å', 'A')
    .replace('ä', 'a')
    .replace('Ä', 'A')
    .replace('ö', 'o')
    .replace('Ö', 'O') // replace scandinavian letters with their a/o counterparts
    .replace(/[^a-zA-Z0-9]+/g, '-') // replace all other characters besides alphanumeric with "-"
    .toLowerCase();
};

var isValidCompanyNameInUrl = nameInUrl => {
  return /^[a-zA-Z0-9\-]+$/.test(nameInUrl);
};

var isNotValidCompanyNameInUrl = nameInUrl => {
  return !isValidCompanyNameInUrl(nameInUrl);
};

module.exports = {
  stringIsNotNullOrEmpty: stringIsNotNullOrEmpty,
  stringIsNullOrEmpty: stringIsNullOrEmpty,
  isValidBusinessId: isValidBusinessId,
  isNotValidBusinessId: isNotValidBusinessId,
  generateCompanyNameInURL: generateCompanyNameInURL,
  isValidCompanyNameInUrl: isValidCompanyNameInUrl,
  isNotValidCompanyNameInUrl: isNotValidCompanyNameInUrl
};
