import React from 'react';

export default class CompanyBase extends React.Component {
  componentDidMount() {
    // NOTE This component only renders for company Routes, and only when we're sure isLoggedIn() has been called so it's safe to do this.
    // NOTE This is for updating change request collection
    this.props.registerActions.loadRegisters();
  }

  componentWillReceiveProps(nextProps) {
    // Check nextProps to see if location changed, if so check for login.
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.props.isLoggedIn();
    }
  }

  render() {
    return <div className="container-fluid">{this.props.children}</div>;
  }
}
