import React from 'react';

export default class Saam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingStatus: false
    };
  }

  checkForToken = () => {
    const { match } = this.props;
    if (match && match.params && match.params.code) {
      this.props.actions
        .authenticationAjax(
          match.params.code,
          `${window.location.protocol}//${window.location.host}`
        )
        .then(result => {
          if (!result) {
            this.setState({ checkingStatus: true });
          }
        })
        .catch(error => {
          this.setState({ checkingStatus: true });
        });
    } else {
      this.setState({ checkingStatus: true });
      setTimeout(() => window.location.replace('/'), 700);
    }
  };

  componentDidMount() {
    this.checkForToken();
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row main-content-area">
          <h1 className="text-center">
            {this.state.checkingStatus
              ? t('Company.Pages.CheckLink.Failed')
              : t('Company.Pages.CheckLink.Header')}
          </h1>
        </div>
      </div>
    );
  }
}
