import * as types from '../actions/action-types';
import get from 'lodash/get';

const initialState = {
  activePage: 0,
  currentFiltergroup: '',
  targetGroupsPager: null,
  targetTypeOptions: ['Facebook', 'Tele'],
  filters: null,
  step: 0,
  newTargetGroup: {
    name: '',
    marketingType: 'Tele',
    filters: {},
    blocklists: []
  },
  blocklists: [],
  results: null,
  loading: false
};

const targetGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_TARGET_GROUP_PAGE:
      return Object.assign({}, state, { activePage: action.data });
    case types.SET_CURRENT_FILTERGROUP:
      return Object.assign({}, state, { currentFiltergroup: action.data });
    case types.RESET_NEW_TARGET_GROUP:
      return Object.assign({}, state, {
        newTargetGroup: {
          ...initialState.newTargetGroup,
          blocklists: state.blocklists
        },
        step: initialState.step,
        results: initialState.results,
        currentFiltergroup: initialState.currentFiltergroup
      });
    case types.FETCH_TARGET_GROUPS:
      return Object.assign({}, state, { targetGroupsPager: action.data });
    case types.FETCH_BLOCK_LISTS:
      return Object.assign({}, state, {
        blocklists: action.data,
      });
    case types.FETCH_TARGET_GROUP_FILTERS_SUCCESS:
      return Object.assign({}, state, { filters: action.data });
    case types.SET_TARGETING_STEP:
      return Object.assign({}, state, { step: action.data });
    case types.SET_TARGETING_LOADING_STATUS:
      return Object.assign({}, state, { loading: action.data });
    case types.UPDATE_NEW_TARGET_GROUP:
      return Object.assign({}, state, {
        newTargetGroup: {
          ...state.newTargetGroup,
          ...action.data
        }
      });
    case types.GET_TARGET_GROUP_SUCCESS:
      return Object.assign({}, state, {
        newTargetGroup: action.data
      });
    case types.GET_TARGET_GROUP_SEARCH_RESULTS_SUCCESS:
      return Object.assign({}, state, {
        results: action.data,
        newTargetGroup: {
          ...state.newTargetGroup,
          count: get(action, 'data.count', 0)
        }
      });

    default:
      // need this for default case
      return state;
  }
};

export default targetGroupReducer;
