import get from 'lodash/get';

import { showUnauthorizedToast } from './errorToasts';
import { Store } from '../store/configureStore';
import AxiosService from '../services/AxiosService';

const toastr = window.toastr;

const userApi = {
  login: data =>
    AxiosService.post('/login', { data })
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        const contentStrings = Store().getState().langState.contentStrings;
        toastr.error(get(contentStrings, 'Company.Pages.Auth.LoggedInFailed', ''));
        return { status: 'error', data: error };
      }),

  logout: () => {
    const sessionState = localStorage.getItem('enento-niam-token');
    return AxiosService.get('/logout', {sessionState})
      .then(response => {
        const contentStrings = Store().getState().langState.contentStrings;
        toastr.info(get(contentStrings, 'Company.Pages.Auth.LoggedOutSuccesfully', ''));
        return { status: 'success', data: response.data };
      })
      .catch(error => ({ status: 'error', data: error }));
  },
  getUserForNavi: () =>
    AxiosService.get('/getUserToken/', {})
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => ({ status: 'error', data: error })),

  getUserToken: receivedUser =>
    AxiosService.get('/getUserToken/', { usr: receivedUser })
      .then(response => {
        setTimeout(() => {
          const contentStrings = Store().getState().langState.contentStrings;
          toastr.info(get(contentStrings, 'Company.Pages.Auth.LoggedInSuccesfully', ''));
        }, 800);
        return { status: 'success', data: response.data };
      })
      .catch(error => {
        setTimeout(() => {
          const contentStrings = Store().getState().langState.contentStrings;
          toastr.error(get(contentStrings, 'Company.Pages.Auth.LoggedInFailed', ''));
        }, 800);

        return { status: 'error', data: error };
      }),
  getUserTokenNiam: params =>
    AxiosService.get('/getUserTokenWithToken/', params)
      .then(response => {
        setTimeout(() => {
          const contentStrings = Store().getState().langState.contentStrings;
          toastr.info(get(contentStrings, 'Company.Pages.Auth.LoggedInSuccesfully', ''));
        }, 800);
        return { status: 'success', data: response.data };
      })
      .catch(error => {
        setTimeout(() => {
          const contentStrings = Store().getState().langState.contentStrings;
          toastr.error(get(contentStrings, 'Company.Pages.Auth.LoggedInFailed', ''));
        }, 800);
        throw new Error('errored while loggin in');
      }),
  getUserTokenTest: (token, url) =>
    AxiosService.get('/getUserTokenWithToken/', { token, url })
      .then(response => {
        setTimeout(() => {
          const contentStrings = Store().getState().langState.contentStrings;
          toastr.info(get(contentStrings, 'Company.Pages.Auth.LoggedInSuccesfully', ''));
        }, 800);
        return { status: 'success', data: response.data };
      })
      .catch(error => {
        setTimeout(() => {
          const contentStrings = Store().getState().langState.contentStrings;
          toastr.error(get(contentStrings, 'Company.Pages.Auth.LoggedInFailed', ''));
        }, 800);
        throw new Error('errored while loggin in');
      }),

  isLoggedIn: () =>
    AxiosService.get('/isLoggedIn/', {})
      .then(response => true)
      .catch(error => {
       //console.log(error.data);
        const redirect = get(error, 'response.data.redirectUrl', '');
        const verifier = get(error, 'response.data.verifier', '');
        if (verifier) {
          localStorage.setItem('enento-niam', verifier);
        }
        if (redirect) {
          // window.location.href = get(error, 'response.data.redirectUrl', '');
          return false;
        }
      }),

  isAtAdmin: () =>
    AxiosService.get('/isAtAdmin/', {})
      .then(response => true)
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
          const { usersLandingPage } = Store().getState().userState;
          window.location.href = `/#${usersLandingPage}`;
        } else {
          return false;
        }
      }),

  isCustomerAdminOrAtAdmin: () =>
    AxiosService.get('/isCustomerAdminOrAtAdmin/', {})
      .then(response => true)
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
          const { usersLandingPage } = Store().getState().userState;
          // window.location.href = `/#${usersLandingPage}`;
        } else {
          return false;
        }
      }),

  isCustomerBasicUserOrCustomerAdminOrAtAdmin: () =>
    AxiosService.get('/isCustomerBasicUserOrCustomerAdminOrAtAdmin/', {})
      .then(response => true)
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
          const { usersLandingPage } = Store().getState().userState;
          // window.location.href = `/#${usersLandingPage}`;
        } else {
          return false;
        }
      }),

  getUserSecurityLevel: user =>
    AxiosService.get('/getUserForNavi/', user)
      .then(response => response.data)
      .catch(error => ({})),
  saveUserAnswer: answer =>
    AxiosService.post('/saveUserAnswer', { answer })
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => ({ status: 'error', data: error })),
  fetchTac: lang =>
    AxiosService.get('/getTac', { lang })
      .then(response => response.data)
      .catch(error => ({})),
  fetchFaq: lang =>
    AxiosService.get('/fetchFaq', { lang })
      .then(response => response.data)
      .catch(error => ({})),
  setFirstLoginDone: () => {
    AxiosService.get('/setFirstLoginDone');
  }
};
export default userApi;
