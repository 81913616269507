export const colors = {
  white: '#ffffff',
  black: '#000000',
  doveGray: '#707070', //#727272
  darkerDoveGray: '#646464',
  altoGray: '#D5D5D5',
  pictonBlue: '#35dcf2',
  bondiBlue: '#008eaa',
  rose: '#FF0A5F',
  wildSand: '#F4F4F4',
  wattleYellow: '#CBDA47',
  shamrockGreen: '#47D7AC',
  tradewindGreen: '#6FBEB5',
  errorRed: 'red',

  /** official AT colours **/

  rodeodust: '#f5ebe6',
  desert: '#dcc8af',
  bark: '#be8f65',

  oceanfoam: '#ddeef2',
  summersky: '#8ec7d4',
  ocean: '#008eaa',

  grassLight: '#d9f1e4',
  grassMedium: '#9bcda0',
  grassDark: '#00a04b',

  springLight: '#f1f4e2',
  springMedium: '#d1db9e',
  springDark: '#a5b83e',

  fireLight: '#fff0dc',
  fireMedium: '#fac88c',
  fireDark: '#f39000',

  appleLight: '#fbdadc',
  appleMedium: '#f18289',
  appleDark: '#e10a19',

  plumLight: '#ebe3ed',
  plumMedium: '#bda0c1',
  plumDark: '#7d4182'
  /*************/
};

export const theme = {
  colors,
  fontFamily: 'Roboto, sans-serif',
  primaryFont: 'Roboto, sans-serif',
  secondaryFont: 'Roboto, sans-serif',
  text: {
    positive: colors.darkerDoveGray,
    error: colors.errorRed
  }
};
