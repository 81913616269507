import get from 'lodash/get';

import * as types from '../actions/action-types';
import registerApi from '../api/register-api';

export const updateRegisterHeadersSuccess = registers => ({
  type: types.GET_REGISTERS_SUCCESS,
  registers
});

export const updateRegisterHeaders = (register, newHeaders) => dispatch =>
  registerApi
    .updateRegisterHeaders(register, newHeaders)
    .then(result => {
      dispatch(updateRegisterHeadersSuccess(result));
    })
    .catch(error => {});

export const loadRegistersSuccess = registers => ({
  type: types.GET_REGISTERS_SUCCESS,
  registers
});

export const loadRegisters = (product = 'GDPR', company = undefined) => dispatch =>
  registerApi
    .getRegisters(product, company)
    .then(result => {
      dispatch(loadRegistersSuccess(result));
    })
    .catch(error => {
      dispatch(loadRegistersSuccess([]));
    });

export const checkRegisterStatusSuccess = register => ({
  type: types.CHECK_REGISTER_STATUS_FOR_PROCESSING_SUCCESS,
  register
});

export const checkRegisterStatus = id => dispatch =>
  registerApi
    .checkRegisterStatus(id)
    .then(register => {
      dispatch(checkRegisterStatusSuccess(register));
    })
    .catch(error => {
      dispatch(checkRegisterStatusSuccess({}));
    });

export const loadSingleRegisterSuccess = register => ({
  type: types.GET_SINGLE_REGISTER_SUCCESS,
  register
});

export const loadSingleRegister = registerId => dispatch =>
  registerApi
    .getRegister(registerId)
    .then(register => {
      dispatch(loadSingleRegisterSuccess(register));
    })
    .catch(error => {});

export const loadRegisterContents = registerId => dispatch =>
  registerApi
    .requestDownloadOfRegisterContents(registerId)
    .then(register => {})
    .catch(error => {
      // TODO:
    });

export const createRegisterSuccess = register => ({
  type: types.CREATE_REGISTER_SUCCESS,
  register
});

export const createRegister = formData => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .createRegister(formData)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(createRegisterSuccess(response.data)));
        } else {
          throw new Error(response.status);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const updateRegisterSuccess = data => ({
  type: types.UPDATE_REGISTER_SUCCESS,
  data
});

export const updateRegister = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .updateRegister(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(updateRegisterSuccess(response.data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const addIdentifierInfoSuccess = data => ({
  type: types.ADD_IDENTIFIERINFO_SUCCESS,
  data
});

export const addIdentifierInfo = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .addIdentifierInfo(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(addIdentifierInfoSuccess(response.data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const addDatatypeInfo = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .addDatatypeInfo(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(addIdentifierInfoSuccess(response.data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const resetTemporaryCsvData = () => ({
  type: types.RESET_TEMPORARY_CSV_DATA
});

export const addTemporaryCsvData = (data, hasHeaders) => ({
  type: types.ADD_TEMPORARY_CSV_DATA_SUCCESS,
  data,
  hasHeaders
});

export const addHeadersSuccess = data => ({
  type: types.ADD_HEADERS_SUCCESS,
  data
});

export const addHeaders = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .addHeaders(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(addHeadersSuccess(response.data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const saveColumnOrder = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .saveColumnOrder(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
export const toggleOrdersLoading = data => ({
  type: types.TOGGLE_ORDER_LOADING,
  data
});
export const getOrdersSuccess = data => ({
  type: types.GET_ORDERS_SUCCESS,
  data
});
export const getOrders = register => dispatch => {
  dispatch(toggleOrdersLoading(true));
  return new Promise((resolve, reject) => {
    registerApi
      .getOrders(register)
      .then(response => {
        dispatch(toggleOrdersLoading(false));
        if (response.status === 'success') {
          resolve(dispatch(getOrdersSuccess(response.data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        dispatch(toggleOrdersLoading(false));
        reject(error);
      });
  });
};

export const orderHealthCheck = (register, product) => dispatch => {
  return new Promise((resolve, reject) => {
    registerApi
      .orderHealthCheck(register, product)
      .then(response => {
        if (response.status === 'success') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const orderRegisterUpdate = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .orderRegisterUpdate(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const setActive = key => ({
  type: types.SET_ACTIVE,
  key
});

export const deleteRegisterSuccess = data => ({
  type: types.DELETE_REGISTER_SUCCESS,
  data
});

export const deleteRegister = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .deleteRegister(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(deleteRegisterSuccess(data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const restoreRegisterSuccess = data => ({
  type: types.RESTORE_REGISTER_SUCCESS,
  data
});

export const restoreRegister = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .restoreRegister(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(restoreRegisterSuccess(data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const setInProgress = key => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .setInProgress(key)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });

export const loadChangeRequestsSuccess = data => ({
  type: types.COMPANY_CHANGE_REQUESTS_LOAD_SUCCESS,
  data
});

export const loadChangeRequests = (page, perPage, company) => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .loadChangeRequests(page, perPage, company)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(loadChangeRequestsSuccess(response.data)));
        } else {
          resolve(dispatch(loadChangeRequestsSuccess([])));
        }
      })
      .catch(error => {
        resolve(dispatch(loadChangeRequestsSuccess([])));
      });
  });

export const updateRecordSuccess = data => ({
  type: types.COMPANY_UPDATE_RECORD_SUCCESS,
  data
});

export const updateRecord = data => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .updateRecord(data)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(updateRecordSuccess(response.data)));
        } else {
          let message = 'Company.Toasts.SomethingWentWrong';
          const responseMessage = get(response, 'data.data.message', '');
          message =
            responseMessage.indexOf('STATUS_DONE') >= 0
              ? 'Company.Toasts.ChangeRequestWasAlreadyHandled'
              : 'Company.Toasts.SomethingWentWrong';
          reject(message);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
export const deleteRecord = record => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .deleteRecord(record)
      .then(response => {
        if (response.status === 'success') {
          resolve();
        } else {
          reject();
        }
      })
      .catch(error => {
        reject(error);
      });
  });
export const loadRecordsSuccess = data => ({
  type: types.GET_RECORDS_SUCCESS,
  data
});

export const loadRecords = (registerId, page, pageLength) => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .loadRecords(registerId, page, pageLength)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(loadRecordsSuccess(response.data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const findRecords = (registerId, page, pageLength, searchTerm) => dispatch =>
  new Promise((resolve, reject) => {
    registerApi
      .findRecords(registerId, page, pageLength, searchTerm)
      .then(response => {
        if (response.status === 'success') {
          resolve(dispatch(loadRecordsSuccess(response.data)));
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
