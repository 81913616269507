import moment from 'moment';
import * as types from '../actions/action-types';

const initialState = {
  currentLanguage: 'fi',
  contentStrings: {}
};

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LANGUAGES_SUCCESS:
      moment.locale(action.currentLanguage);
      return Object.assign({}, state, {
        contentStrings: action.contentStrings,
        currentLanguage: action.currentLanguage
      });

    case types.CHANGE_LANGUAGE:
      moment.locale(action.currentLanguage);
      return Object.assign({}, state, { currentLanguage: action.currentLanguage });

    default:
      // need this for default case
      return state;
  }
};

export default langReducer;
