import AxiosService from '../services/AxiosService';

/**
 * Get all content strings with certain lang
 */
const getContentStrings = language =>
  AxiosService.get('/getContentStrings', { language })
    .then(response => response.data)
    .catch(error => error);

export default getContentStrings;
