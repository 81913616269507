/**
 * RecordChangeRequest.js
 *
 * @description ::
 * @docs        :: http://sailsjs.org/documentation/concepts/models-and-orm/models
 */

const STATUS_NEW = 'NEW';
const STATUS_INPROGRESS = 'INPROGRESS';
const STATUS_DONE = 'DONE';

const REQUEST_TYPE_DELETE = 'DELETE';
const REQUEST_TYPE_CHANGE = 'CHANGE';

module.exports = {
  STATUS_NEW: STATUS_NEW,
  STATUS_INPROGRESS: STATUS_INPROGRESS,
  STATUS_DONE: STATUS_DONE,

  REQUEST_TYPE_DELETE: REQUEST_TYPE_DELETE,
  REQUEST_TYPE_CHANGE: REQUEST_TYPE_CHANGE,

  attributes: {
    createdAt: { type: 'string', autoCreatedAt: true },
    updatedAt: { type: 'string', autoUpdatedAt: true },
    status: {
      type: 'string',
      isIn: [STATUS_NEW, STATUS_INPROGRESS, STATUS_DONE],
      defaultsTo: STATUS_NEW
    },
    handler: {
      model: 'User'
    },
    requestType: {
      type: 'string',
      required: true
    },
    changes: {
      type: 'string',
      defaultsTo: '[]'
    },
    identifyingInformationChanges: {
      collection: 'IdentifyingInformationChangeRequest',
      via: 'changeRequest'
    },
    record: {
      model: 'Record',
      required: true
    },
    register: {
      model: 'Register',
      required: true
    }
  }
};
