/**
 * User.js
 *
 * @description ::
 * @docs        :: http://sailsjs.org/documentation/concepts/models-and-orm/models
 */

const accessLevel = {
  ADMIN: 'GDPR_AT_KAYTTAJA',
  COMPANY_ADMIN: 'GDPR_ASIAKAS_ADMIN',
  COMPANY_USER: 'GDPR_ASIAKAS_KAYTTAJA',
  NONE: 'NONE'
};

const featureAccessRoles = {
  GEPARDI_MARKKINOINTI: 'GEPARDI_MARKKINOINTI',
  GDPR_MARKKINOINTI_ILMAN_TULOIK: 'GDPR_MARKKINOINTI_ILMAN_TULOIK',
  GDPR_SCYTHE_ADMIN: 'GDPR_SCYTHE_ADMIN',
  GDPR_SCYTHE_KAYTTAJA: 'GDPR_SCYTHE_KAYTTAJA',
  ROLE_GDPR_TESTIKAYTTAJA: 'ROLE_GDPR_TESTIKAYTTAJA'
};

module.exports = {
  accessLevel: accessLevel,
  featureAccessRoles: featureAccessRoles,

  attributes: {
    createdAt: { type: 'string', autoCreatedAt: true },
    updatedAt: { type: 'string', autoUpdatedAt: true },
    name: {
      type: 'string',
      required: true
    },
    company: {
      model: 'Company',
      required: true
    },
    accessRole: {
      type: 'string',
      isIn: Object.values(accessLevel),
      required: true
    },
    featureAccessRoles: {
      type: 'json'
    },
    kapyIdentifier: {
      type: 'string',
      required: true,
      unique: true
    },
    isFirstLogin: {
      type: 'boolean',
      defaultsTo: true
    },
    hasAcceptedTermsAndConditions: {
      type: 'boolean',
      defaultsTo: false
    },
    deleted: {
      type: 'boolean',
      defaultsTo: false
    }
  }
};
