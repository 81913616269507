/**
 * RegexUtil
 *
 * @description :: Utility class for holding regexes
 *
 */

/**
 * Test for hetu format.
 * NOTE: this accepts NO whitespace whatsoever. Remove it before testing.
 * NOTE: ONLY use this bearing in mind that this does NOT test hetus properly since it doesn't
 * - test for proper day of month
 * - count the checksum
 */
const HETUREGEX = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(\d\d)([-+Aa])(\d\d\d)([0-9A-Za-z])$/;

const SVHETUREGEX = /^(19|20)?(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([-+]|\s)\d{4}|(19|20)?(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{4}$/;

/**
 * Used to normalize swedish hetus for better search results.
 * Returns finnish hetu as is and swedish national id like this: 921231-1234
 * example: normalizeHetu('199205031234') ==> 920503-1234
 */
const normalizeHetu = hetu => {
  if (HETUREGEX.test(hetu)) return hetu;

  const YYMMDDNNNN = /^(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{4}$/;
  const YYMMDDdashNNNN = /^(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([-])\d{4}$/;
  const YYMMDDplusNNNN = /^(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([+])\d{4}$/;
  const YYMMDDwhitespaceNNNN = /^(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([\s])\d{4}$/;

  const YYYYMMDDNNNN = /^(19|20)(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{4}$/;
  const YYYYMMDDdashNNNN = /^(19|20)(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([-])\d{4}$/;
  const YYYYMMDDplusNNNN = /^(19|20)(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([+])\d{4}$/;
  const YYYYMMDDwhitespaceNNNN = /^(19|20)(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([\s])\d{4}$/;

  let normalizedvalue = hetu;
  if (YYMMDDNNNN.test(hetu)) {
    normalizedvalue = hetu.substring(0, 6) + '-' + hetu.substring(6);
  }
  if (YYMMDDdashNNNN.test(hetu) || YYMMDDplusNNNN.test(hetu) || YYMMDDwhitespaceNNNN.test(hetu)) {
    normalizedvalue = hetu.substring(0, 6) + '-' + hetu.substring(7);
  }
  if (YYYYMMDDNNNN.test(hetu)) {
    normalizedvalue = hetu.substring(2, 8) + '-' + hetu.substring(8);
  }
  if (
    YYYYMMDDdashNNNN.test(hetu) ||
    YYYYMMDDplusNNNN.test(hetu) ||
    YYYYMMDDwhitespaceNNNN.test(hetu)
  ) {
    normalizedvalue = hetu.substring(2, 8) + '-' + hetu.substring(9);
  }

  return normalizedvalue;
};

//NOTE: this accepts NO whitespace whatsoever. Remove it before testing.
const EMAILREGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const normalizeEmail = value => value.replace(/\s/g, '').toLowerCase();

/**
 * This regex matches finnish mobile phone numbers as defined here:
 * https://www.viestintavirasto.fi/attachments/maaraykset/M_32_S_2016.pdf
 *
 * They may or may not have the international code +358 or 00358
 * They begin with either 04x or 050 but leading zero is omitted if international code is given
 * If numbers start with 049 they must have 9 other numbers (total of 11 significant numbers i.e. not counting international code or leading zero)
 * If numbers start with 04x (x != 9) or 050 they must have 6-8 other numbers (total of 8-10 significant numbers i.e. not counting international code or leading zero)
 *
 * Liikenne matkaviestinverkkoihin ohjataan 04- ja 050-alkuisella matkaviestinverkon suuntanumerolla, joka muodostuu kaukotunnuksesta 0 ja matkaviestinverkon tunnuksesta.
 * Matkaviestinverkkojen tilaajanumerot Vähimmäispituus Enimmäispituus (Huom: kansallinen (merkitsevä) numero = ilman etunollaa)
 * 049-alkuiset numerot                 11              11
 * Muut tilaajanumerot                  8               10
 *
 * NOTE: this accepts NO whitespace whatsoever. Remove it before testing.
 */
const FINNISHPHONENUMBERREGEX = /^(((\+|00)358)|0)((49[0-9]{9})|(50[0-9]{6,8})|(4[0-8][0-9]{6,8}))$/;

const SWEDISHPHONENUMBERREGEX = /^(((\+|00)46)|0)((49[0-9]{9})|(50[0-9]{6,8})|(4[0-8][0-9]{6,8}))$/;

module.exports = {
  hetuRegex: HETUREGEX,
  svHetuRegex: SVHETUREGEX,
  normalizeHetu,
  emailRegex: EMAILREGEX,
  normalizeEmail,
  finnishPhoneNumberRegex: FINNISHPHONENUMBERREGEX,
  swedishPhoneNumberRegex: SWEDISHPHONENUMBERREGEX
};
