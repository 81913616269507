/**
 * ReportSubscriptions.js
 *
 * @description ::
 * @docs        :: http://sailsjs.org/documentation/concepts/models-and-orm/models
 */

var type = {
  B2C_REPORT: 1
};

module.exports = {
  type,

  attributes: {
    createdAt: { type: 'string', autoCreatedAt: true },
    updatedAt: { type: 'string', autoUpdatedAt: true },
    type: {
      type: 'number',
      required: true,
      isIn: Object.values(type)
    },
    subscriptionemail: {
      model: 'SubscriptionEmail'
    },
  }
};
