/**
 * Order.js
 *
 * @description ::
 * @docs        :: http://sailsjs.org/documentation/concepts/models-and-orm/models
 */

const product = {
  healthcheck: 'healthcheck',
  dataupdate: 'dataupdate'
};
const variation = {
  phone: 'phone',
  address: 'address',
  both: 'both'
};

const status = {
  OUTDATED: 'OUTDATED',
  READY: 'READY',
  INPROGRESS: 'INPROGRESS',
  NEW: 'NEW'
};

module.exports = {
  product,
  variation,
  status,
  attributes: {
    createdAt: { type: 'string', autoCreatedAt: true },
    updatedAt: { type: 'string', autoUpdatedAt: true },
    product: {
      type: 'string',
      required: true,
      isIn: Object.values(product)
    },
    variation: {
      type: 'string',
      required: true,
      isIn: Object.values(variation)
    },
    orderedBy: {
      model: 'User'
    },
    register: {
      model: 'Register'
    },
    status: {
      type: 'string',
      required: true
    },
    reportfile: {
      type: 'string',
      allowNull: true
    },
    updatefile: {
      type: 'string',
      allowNull: true
    },
    phonereportfile: {
      type: 'string',
      allowNull: true
    },
    phoneupdatefile: {
      type: 'string',
      allowNull: true
    },
    invoicingStatus: {
      type: 'json'
    }
  }
};
