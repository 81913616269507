/**
 * RegisterExport.js
 *
 * @description ::
 * @docs        :: http://sailsjs.org/documentation/concepts/models-and-orm/models
 */

var status = {
  STATUS_NEW: 'NEW', // newly created export, waiting to be handled
  STATUS_MATERIAL_HANDLING_ONGOING: 'STATUS_MATERIAL_HANDLING_ONGOING',
  STATUS_MATERIAL_HANDLING_FAILED: 'STATUS_MATERIAL_HANDLING_FAILED',
  STATUS_READY: 'READY'
};

module.exports = {
  status,
  attributes: {
    createdAt: { type: 'string', autoCreatedAt: true },
    updatedAt: { type: 'string', autoUpdatedAt: true },
    register: {
      model: 'Register',
      required: true
    },
    createdBy: {
      model: 'User',
      required: true
    },
    ready: {
      type: 'boolean',
      defaultsTo: false
    },
    status: {
      type: 'string',
      defaultsTo: status.STATUS_NEW,
      isIn: Object.values(status)
    }
  }
};
