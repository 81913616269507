import * as types from '../actions/action-types';

const initialState = {
  subscriptions: [],
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORTSUBSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, { subscriptions: action.subscriptions.data });

    case types.CREATE_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        subscriptions: [...state.subscriptions, action.subscription.data]
      });

    default:
      // need this for default case
      return state;
  }
};

export default reportReducer;
