/**
 * ColumnDatatype.js
 *
 * @description ::
 * @docs        :: http://sailsjs.org/documentation/concepts/models-and-orm/models
 */

var datatype = {
  IDENTIFYING_INFO_PHONE: 'PHONE',
  IDENTIFYING_INFO_EMAIL: 'EMAIL',
  IDENTIFYING_INFO_HETU: 'HETU',
  IDENTIFYING_INFO_CUSTOMERID: 'CUSTOMERID',
  IDENTIFYING_INFO_ORGANIZATIONID: 'ORGANIZATIONID'
};

var type = {
  TYPE_CHOSEN: 'CHOSEN',
  TYPE_SUGGESTED: 'SUGGESTED'
};

module.exports = {
  type: type,
  datatype: datatype,
  attributes: {
    createdAt: { type: 'string', autoCreatedAt: true },
    updatedAt: { type: 'string', autoUpdatedAt: true },
    columnNumber: {
      type: 'number',
      required: true
    },
    datatype: {
      type: 'string',
      isIn: Object.values(datatype),
      required: true
    },
    columnHeader: {
      type: 'string',
      required: true
    },
    type: {
      type: 'string',
      isIn: Object.values(type),
      required: true
    },
    count: {
      type: 'number',
      required: false
    },
    register: {
      model: 'Register',
      required: true
    }
  }
};
