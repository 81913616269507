import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
// import createHistory from 'history/createBrowserHistory';
import { createHashHistory } from 'history';
import reducers from '../reducers';

let store = {};

export const Store = () => store;

// Create a history of your choosing (we're using a browser history in this case)
// export const history = createHistory();
export const history = createHashHistory();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

export const configureStore = initialState => {
  store = createStore(reducers, applyMiddleware(thunk, middleware), initialState);
  return store;
};
