/**
 * Batch.js
 *
 * @description ::
 * @docs        :: http://sailsjs.org/documentation/concepts/models-and-orm/models
 */

module.exports = {
  STATUS_NEW: 'NEW', // newly created batch, waiting for materials to be uploaded by user
  STATUS_ERRORED: 'ERRORED', // batch failed before material was successfully uploaded
  STATUS_MATERIAL_UPLOADED: 'MATERIAL_UPLOADED', // materials have been uploaded and inserted into database, waiting for validation
  STATUS_MATERIAL_VALIDATION_ONGOING: 'MATERIAL_VALIDATION_ONGOING', // validation ongoing
  STATUS_MATERIAL_VALIDATION_PASSED: 'MATERIAL_VALIDATION_PASSED', // validation passed, waiting for user to choose identifiers
  STATUS_MATERIAL_VALIDATION_FAILED: 'MATERIAL_VALIDATION_FAILED', // validation failed, batch is REJECTED
  STATUS_IDENTIFIERS_CHOSEN: 'IDENTIFIERS_CHOSEN', // identifiers are chosen, waiting for user to choose headers
  STATUS_HEADERS_CHOSEN: 'HEADERS_CHOSEN', // headers are chosen, waiting for batch data to be handled
  STATUS_MATERIAL_HANDLING_ONGOING: 'MATERIAL_HANDLING_ONGOING', // handling material is ongoing
  STATUS_MATERIAL_HANDLING_FAILED: 'MATERIAL_HANDLING_FAILED', // handling material (=inserting into database as proper data) failed, batch is REJECTED
  STATUS_READY: 'READY', // Batch material handled successfully

  SOURCE_REST_FILE_API: 'REST_FILE_API',
  API_OPERATIONS_NEW_REGISTER: 'NEW_REGISTER',

  attributes: {
    createdAt: { type: 'string', autoCreatedAt: true },
    updatedAt: { type: 'string', autoUpdatedAt: true },
    register: {
      model: 'Register',
      required: true
    },
    status: {
      type: 'string',
      required: true
    },
    batchMaterial: {
      type: 'string',
      allowNull: true
    },
    batchMaterialEncoding: {
      type: 'string',
      allowNull: true
    },
    //this actually means that the first batch material row contains headers;
    hasHeader: {
      type: 'boolean',
      allowNull: true
    },
    errors: {
      type: 'string',
      allowNull: true
    },
    source: {
      type: 'string',
      allowNull: true
    },
    // fileApiPath & fileApiOperation are only related to the SFTP file api
    // They should not be needed in the web app but let's add them anyway for sake of completeness...
    fileApiPath: {
      type: 'string',
      allowNull: true
    },
    fileApiOperation: {
      type: 'string',
      allowNull: true
    }
  }
};
