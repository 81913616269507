import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { showErrorToast, showUnauthorizedToast } from './errorToasts';
import { Store } from '../store/configureStore';
import AxiosService from '../services/AxiosService';

import axios from 'axios';

const toastr = window.toastr;
const DEV_ENV = window.location.href.indexOf('dev.asiakastieto') >= 0;

/**
 * Get all registers
 */
const registerApi = {
  createRegister: formData => {
    const user = Store().getState().userState.user;
    const theUrl = DEV_ENV
      ? 'http://dev.asiakastieto.fi:8080/createRegister'
      : '/api/createRegister';
    return axios
      .post(theUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          user
        }
      })
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      });
  },
  updateRegister: data =>
    AxiosService.post('/updateRegister', data)
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),

  addIdentifierInfo: data =>
    AxiosService.post('/addIdentifierInfo', data)
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  addDatatypeInfo: data =>
    AxiosService.post('/addDatatypeInfo', data)
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  addHeaders: data =>
    AxiosService.post('/addHeaders', data)
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  saveColumnOrder: data =>
    AxiosService.post('/saveColumnOrder', data)
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  deleteRegister: data =>
    AxiosService.post('/updateRegister', data)
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  restoreRegister: data =>
    AxiosService.post('/updateRegister', data)
      .then(response => ({ status: 'success', data: response.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  updateRegisterHeaders: (register, newHeaders) => {
    const { contentStrings } = Store().getState().langState;
    return AxiosService.post('/updateRegisterHeaders', { register, newHeaders })
      .then(response => {
        toastr.info(get(contentStrings, 'Company.Toasts.RegisterUpdatedSuccesfully', ''));
        return { status: 'success', data: response.data };
      })
      .catch(error => {
        showErrorToast(error);
        return { status: 'error', data: error };
      });
  },
  getRegisters: (product = 'GDPR', company = undefined) =>
    AxiosService.get('/getRegisters', {product, company})
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return [];
      }),
  checkRegisterStatus: id =>
    AxiosService.get('/checkRegisterStatus', { id })
      .then(response => response.data)
      .catch(error => ({})),
  getRegister: registerId =>
    AxiosService.get('/getRegister', { registerId })
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return null;
      }),
  requestDownloadOfRegisterContents: registerId =>
    AxiosService.get('/requestDownloadOfRegisterContents', { registerId })
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return error;
      }),
  loadChangeRequests: (page, perPage, company) =>
    AxiosService.get('getChangeRequests', { page, perPage, company })
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  setInProgress: key =>
    AxiosService.post('setInProgress', { key })
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  deleteRecord: record =>
    AxiosService.post('deleteRecord', record)
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error.response };
      }),
  updateRecord: data =>
    AxiosService.post('updateRecord', { data })
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error.response };
      }),
  loadRecords: (registerId, page, pageLength) =>
    AxiosService.get('getRecords', { register: registerId, page, pageLength })
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  findRecords: (registerId, page, pageLength, searchTerm) =>
    AxiosService.post('findFromRecords', { register: registerId, page, pageLength, searchTerm })
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  orderRegisterUpdate: registerId =>
    AxiosService.post('orderRegisterUpdate', { register: registerId })
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  orderHealthCheck: (register, product) =>
    AxiosService.post('orderHealthCheck', { register, product })
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      }),
  getOrders: register =>
    AxiosService.get('getOrders', { register })
      .then(result => ({ status: 'success', data: result.data }))
      .catch(error => {
        if (error.response.status === 403) {
          showUnauthorizedToast(error);
        }
        return { status: 'error', data: error };
      })
};
export default registerApi;
