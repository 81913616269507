import * as types from '../actions/action-types';

const initialState = {
  authOptions: [],
  OIDCAuthUrl: '',
  isAuthenticated: false,
  ownRecords: [],
  currentCompanyName: '',
  code: null,
  authPair: null
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_AUTH_OPTIONS_SUCCESS:
      return {
        ...state,
        authOptions: action.data.options,
        currentCompanyName: action.data.companyName,
        OIDCAuthUrl: action.data.OIDCAuthUrl
      };

    case types.LOAD_AUTH_OPTIONS_FAILED:
      return { ...state, currentCompanyName: '', authOptions: [], OIDCAuthUrl: '' };

    case types.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        ownRecords: action.records,
        code: action.code,
        authPair: action.authPair
      };

    case types.CUSTOMER_LOGOUT_SUCCESS:
      return { ...state, isAuthenticated: false, ownRecords: [] };

    default:
      // need this for default case
      return state;
  }
};

export default customerReducer;
