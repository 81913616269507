// Registers
export const GET_REGISTERS_SUCCESS = 'GET_REGISTERS_SUCCESS';
export const GET_SINGLE_REGISTER_SUCCESS = 'GET_SINGLE_REGISTER_SUCCESS';
export const GET_RECORDS_SUCCESS = 'GET_RECORDS_SUCCESS';
export const GET_RECORD_SUCCESS = 'GET_RECORD_SUCCESS';
export const GET_SCYTHE_CONFIG_SUCCESS = 'GET_SCYTHE_CONFIG_SUCCESS';
export const CREATE_REGISTER_SUCCESS = 'CREATE_REGISTER_SUCCESS';
export const UPDATE_REGISTER_SUCCESS = 'UPDATE_REGISTER_SUCCESS';
export const DELETE_REGISTER_SUCCESS = 'DELETE_REGISTER_SUCCESS';
export const RESTORE_REGISTER_SUCCESS = 'RESTORE_REGISTER_SUCCESS';
export const ADD_IDENTIFIERINFO_SUCCESS = 'ADD_IDENTIFIERINFO_SUCCESS';
export const ADD_HEADERS_SUCCESS = 'ADD_HEADERS_SUCCESS';
export const SET_ACTIVE = 'SET_ACTIVE';
export const CHECK_REGISTER_STATUS_FOR_PROCESSING_SUCCESS =
  'CHECK_REGISTER_STATUS_FOR_PROCESSING_SUCCESS';
export const ADD_TEMPORARY_CSV_DATA_SUCCESS = 'ADD_TEMPORARY_CSV_DATA_SUCCESS';
export const RESET_TEMPORARY_CSV_DATA = 'RESET_TEMORARY_CSV_DATA';

// Orders
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const TOGGLE_ORDER_LOADING = 'TOGGLE_ORDER_LOADING';

// Companies
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const COMPANY_CHANGE_REQUESTS_LOAD_SUCCESS = 'COMPANY_CHANGE_REQUESTS_LOAD_SUCCESS';
export const COMPANY_UPDATE_RECORD_SUCCESS = 'COMPANY_UPDATE_RECORD_SUCCESS';
export const RESTORE_COMPANY_SUCCESS = 'RESTORE_COMPANY_SUCCESS';
export const COMPANY_GET_LOGS_SUCCESS = 'COMPANY_GET_LOGS_SUCCESS';

// Company usermanagement
export const GET_USER_ACCESSES_SUCCESS = 'GET_USER_ACCESSES_SUCCESS';
export const DELETE_USER_ACCESS_SUCCESS = 'DELETE_USER_ACCESS_SUCCESS';
export const CREATE_USER_ACCESS_SUCCESS = 'CREATE_USER_ACCESS_SUCCESS';
export const GET_USERMANAGEMENT_USERS_SUCCESS = 'GET_USERMANAGEMENT_USERS_SUCCESS';
export const UPDATE_USER_ACCESSROLE_SUCCESS = 'UPDATE_USER_ACCESSROLE_SUCCESS';
export const GET_COMPANY_REGISTERS_SUCCESS = 'GET_COMPANY_REGISTERS_SUCCESS';

// Subscriptions
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_REGISTERS_SUCCESS = 'GET_SUBSCRIPTION_REGISTERS_SUCCESS';
export const GET_SUBSCRIPTION_EMAIL_SUCCESS = 'GET_SUBSCRIPTION_EMAIL_SUCCESS';
export const CREATE_SUBSCRIPTION_EMAIL_SUCCESS = 'CREATE_SUBSCRIPTION_EMAIL_SUCCESS';

// Languages
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// Users
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const USER_AUTHENTICATION_SUCCESS = 'USER_AUTHENTICATION_SUCCESS';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const GET_USER_FOR_NAVI_SUCCESS = 'GET_USER_FOR_NAVI_SUCCESS';
export const GET_TAC_FOR_FIRST_LOGIN_SUCCESS = 'GET_TAC_FOR_FIRST_LOGIN_SUCCESS';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';

// Customers
export const LOAD_AUTH_OPTIONS_SUCCESS = 'LOAD_AUTH_OPTIONS_SUCCESS';
export const LOAD_AUTH_OPTIONS_FAILED = 'LOAD_AUTH_OPTIONS_FAILED';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const USER_AUTHENTICATION_TEST_SUCCESS = 'USER_AUTHENTICATION_TEST_SUCCESS';
export const CUSTOMER_LOGOUT_SUCCESS = 'CUSTOMER_LOGOUT_SUCCESS';

// ADMIN

export const ADMIN_GET_TAC_SUCCESS = 'ADMIN_GET_TAC_SUCCESS';
export const ADMIN_ADD_TAC_SUCCESS = 'ADMIN_ADD_TAC_SUCCESS';
export const ADMIN_DELETE_TAC_SUCCESS = 'ADMIN_DELETE_TAC_SUCCESS';
export const ADMIN_UPDATE_TAC_SUCCESS = 'ADMIN_UPDATE_TAC_SUCCESS';

export const ADMIN_GET_FAQ_SUCCESS = 'ADMIN_GET_FAQ_SUCCESS';


// Target groups
export const FETCH_TARGET_GROUPS = 'FETCH_TARGET_GROUPS';
export const FETCH_TARGET_GROUP_FILTERS_SUCCESS = 'FETCH_TARGET_GROUP_FILTERS_SUCCESS';
export const FETCH_BLOCK_LISTS = 'FETCH_BLOCK_LISTS';
export const SET_TARGETING_STEP = 'SET_TARGETING_STEP';
export const SET_TARGETING_LOADING_STATUS = 'SET_TARGETING_LOADING_STATUS';
export const SET_CURRENT_FILTERGROUP = 'SET_CURRENT_FILTERGROUP';
export const UPDATE_NEW_TARGET_GROUP = 'UPDATE_NEW_TARGET_GROUP';
export const GET_TARGET_GROUP_SUCCESS = 'GET_TARGET_GROUP_SUCCESS';
export const GET_TARGET_GROUP_SEARCH_RESULTS_SUCCESS= 'GET_TARGET_GROUP_SEARCH_RESULTS_SUCCESS';
export const RESET_NEW_TARGET_GROUP = 'RESET_NEW_TARGET_GROUP';
export const SET_ACTIVE_TARGET_GROUP_PAGE = 'SET_ACTIVE_TARGET_GROUP_PAGE';


// Reporting

export const GET_REPORTSUBSCRIPTIONS_SUCCESS = 'GET_REPORTSUBSCRIPTIONS_SUCCESS';
