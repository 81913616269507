import React from 'react';

const user = {
  sub: 'ATIETOASKATS',
  ytunnus: '0123456-2',
  updated_at: '0',
  name: 'Gepardi AT admin',
  roles: [
    'cn=GDPR_ASIAKAS_ADMIN,ou=groups,dc=attest,dc=fi',
    'cn=JokinToinen,ou=groups,dc=attest,dc=fi'
  ],
  userid: 'ATIETOASKATS'
};

export default class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingStatus: false
    };
  }

  checkForToken = () => {
    const { href } = window.location || '';

    if (href.indexOf('access_token=') >= 0) {
      let token = href.substring(href.indexOf('access_token=') + 13);
      token = token.indexOf('&') >= 0 ? token.substring(0, token.indexOf('&')) : token;
      // TODO have nixu add localhost / some dev url to accepted redirecturls list so we dont have to use these mock users anymore
      if (window.location.href.indexOf('bl') >= 0) {
        this.props.actions.authentication(user);
        this.props.actions.getUserForNavi(user);
      } else {
        this.props.actions.authenticationAjax(
          token,
          `${window.location.protocol}//${window.location.host}`
        );
      }
    }
  };

  componentDidMount() {
    const { href } = window.location || '';
    if (href.indexOf('access_token=') < 0) {
      this.props.isLoggedIn();
    } else {
      this.checkForToken();
    }
  }

  // componentWillReceiveProps() {
  //   this.checkForToken();
  // }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row main-content-area">
          <h1 className="text-center">
            {this.state.checkingStatus
              ? t('Company.Pages.CheckLink.Failed')
              : t('Company.Pages.CheckLink.Header')}
          </h1>
        </div>
      </div>
    );
  }
}
