import { Store } from '../store/configureStore';
import isEmpty from 'lodash/isEmpty';
import _ from 'lodash';

const toastr = window.toastr;

export const showErrorToast = error => {
  if (error.response.status === 403) {
    showUnauthorizedToast(error);
  } else {
    const { contentStrings } = Store().getState().langState;
    toastr.error(_.get(contentStrings, 'Company.Toasts.SomethingWentWrong', ''));
  }
};

export const showUnauthorizedToast = error => {
  if (error.response.status === 403) {
    const { contentStrings } = Store().getState().langState;
    setTimeout(() => {
      if (!isEmpty(contentStrings) && error.response.data.shouldToast)
        toastr.error(_.get(contentStrings, 'Company.Toasts.UnAuthorizedAction', ''));
    }, 800);
  }
};
