import * as types from '../actions/action-types';

const initialState = {
  subscriptions: [],
  registers: [],
  email: '',
  emailValue: ''
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUBSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, { subscriptions: action.subscriptions.data });

    case types.GET_SUBSCRIPTION_REGISTERS_SUCCESS:
      return Object.assign({}, state, { registers: action.registers.data });

    case types.CREATE_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        subscriptions: [...state.subscriptions, action.subscription.data]
      });

    case types.DELETE_SUBSCRIPTION_SUCCESS: {
      const removedId = action.subscription.data[0] ? action.subscription.data[0].id : 0;
      const modifiedSubscriptions = state.subscriptions.filter(
        subscription => subscription.id !== removedId
      );
      return Object.assign({}, state, { subscriptions: modifiedSubscriptions });
    }
    case types.GET_SUBSCRIPTION_EMAIL_SUCCESS:
      return Object.assign({}, state, { email: action.email.data, emailValue: action.email.data });

    case types.CREATE_SUBSCRIPTION_EMAIL_SUCCESS:
      return Object.assign({}, state, { email: action.email.data });

    default:
      // need this for default case
      return state;
  }
};

export default subscriptionReducer;
