/**
 * Faq.js
 *
 * @description ::
 * @docs        :: http://sailsjs.org/documentation/concepts/models-and-orm/models
 */

const STATUS_NEW = 'DRAFT';
const STATUS_DONE = 'PUBLISHED';

module.exports = {
  STATUS_DRAFT: STATUS_NEW,
  STATUS_PUBLISHED: STATUS_DONE,

  attributes: {
    createdAt: { type: 'string', autoCreatedAt: true },
    updatedAt: { type: 'string', autoUpdatedAt: true },
    groupingid: {
      type: 'number',
      required: true
    },
    lang: {
      type: 'string',
      required: true
    },
    status: {
      type: 'string',
      isIn: [STATUS_NEW, STATUS_DONE],
      defaultsTo: STATUS_NEW
    },
    title: {
      type: 'string'
    },
    content: {
      type: 'string'
    },
    parent: {
      model: 'FaqGroup',
      required: true
    }
  }
};
