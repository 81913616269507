import isEmpty from 'lodash/isEmpty';
import * as types from '../actions/action-types';

const initialState = {
  registers: [],
  deletedRegisters: [],
  processedRegister: {},
  active: -1,
  isFetching: false,
  changeRequests: [],
  records: [],
  record: {},
  temporaryCsvDatas: [],
  temporaryHasHeaders: false,
  registerContents: {
    registerId: -1,
    contens: []
  },
  scythe: {}
};

const isDeleted = register => register.deleted;
const isNotDeleted = register => !register.deleted;

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REGISTERS_SUCCESS:
      return {
        ...state,
        registers: action.registers.filter(isNotDeleted),
        deletedRegisters: action.registers.filter(isDeleted)
      };

    case types.CHECK_REGISTER_STATUS_FOR_PROCESSING_SUCCESS:
      return Object.assign({}, state, { processedRegister: action.register });

    case types.GET_SINGLE_REGISTER_SUCCESS:
      return Object.assign({}, state, { register: action.register });

    case types.CREATE_REGISTER_SUCCESS:
      return Object.assign({}, state, { registers: [...state.registers, action.register] });

    case types.UPDATE_REGISTER_SUCCESS: {
      const updatedRegistersList = state.registers;
      updatedRegistersList.map(value => {
        const newValue = value;
        if (value.id === action.data.id) {
          newValue.name = action.data.name;
          newValue.description = action.data.description;
        }
        return newValue;
      });
      return Object.assign({}, state, { registers: updatedRegistersList });
    }

    case types.SET_ACTIVE:
      return Object.assign({}, state, { active: action.key });

    case types.DELETE_REGISTER_SUCCESS:
      return {
        ...state,
        active: -1,
        registers: state.registers.filter(r => r.id !== action.data.register.id),
        deletedRegisters: [...state.deletedRegisters, action.data.register]
      };

    case types.RESTORE_REGISTER_SUCCESS:
      return {
        ...state,
        active: -1,
        registers: [...state.registers, action.data.register],
        deletedRegisters: state.deletedRegisters.filter(r => r.id !== action.data.register.id)
      };

    case types.COMPANY_CHANGE_REQUESTS_LOAD_SUCCESS:
      return Object.assign({}, state, { changeRequests: action.data });

    case types.COMPANY_UPDATE_RECORD_SUCCESS: {
      const newChangeRequests = state.changeRequests;
      newChangeRequests.changeRequests = newChangeRequests.changeRequests.map(value => {
        const newValue = value;
        if (value.id === action.data.id) {
          newValue.status = action.data.status;
        }
        return newValue;
      });
      let { registers } = state;
      const { data } = action;
      const registerId = data.register;
      registers = registers.map(register => {
        const updatedRegister = register;
        if (updatedRegister.id === registerId) {
          updatedRegister.recordChangeRequests = updatedRegister.recordChangeRequests.map(cr => {
            let newValue = cr;
            if (cr.id === data.id) {
              newValue = data;
            }
            return newValue;
          });
        }
        return updatedRegister;
      });
      return Object.assign({}, state, { changeRequests: newChangeRequests, registers });
    }

    case types.GET_RECORDS_SUCCESS:
      return Object.assign({}, state, { records: action.data });

    case types.GET_RECORD_SUCCESS:
      return { ...state, record: action.data };
    case types.GET_SCYTHE_CONFIG_SUCCESS:
      return { ...state, scythe: action.data };
      
    case types.ADD_TEMPORARY_CSV_DATA_SUCCESS: {
      const allreadyAdded = state.temporaryCsvDatas.filter(value => value.id === action.data.id);
      if (isEmpty(allreadyAdded)) {
        return Object.assign({}, state, {
          temporaryCsvDatas: [...state.temporaryCsvDatas, action.data],
          temporaryHasHeaders: action.hasHeaders
        });
      }
      return state;
    }

    case types.RESET_TEMPORARY_CSV_DATA:
      return Object.assign({}, state, { temporaryCsvDatas: [], temporaryHasHeaders: false });

    default:
      // need this for default case
      return state;
  }
};

export default registerReducer;
