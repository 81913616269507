import React from 'react';
import ReactTooltip from 'react-tooltip';

export default class ChangeRequestNotification extends React.Component {
  render() {
    const { t, elementId, newRequestsAmount } = this.props;

    return (
      <span
        data-tip
        data-for={`listing-tooltop-for-${elementId}`}
        className={'o-button-notification'}
        id={`notification-${elementId}`}
      >
        {newRequestsAmount < 10 ? newRequestsAmount : '9+'}
        <ReactTooltip
          id={`listing-tooltop-for-${elementId}`}
          place="top"
          type="dark"
          effect="solid"
        >
          {t('Company.ToolTips.NewChangeRequests')}
        </ReactTooltip>
      </span>
    );
  }
}
