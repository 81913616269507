import { push } from 'react-router-redux';

export const navigate = to => dispatch => {
  dispatch(push(`${to}`));
};

// See https://github.com/facebook/jest/issues/890#issuecomment-295939071
export const redirect = to => {
  window.location.assign(to);
};
