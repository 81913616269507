import get from 'lodash/get';

import * as types from '../actions/action-types';
import { navigate } from './navigate';
import userApi from '../api/user-api';

export const login = data => dispatch =>
  userApi
    .login(data)
    .then(result => {
      if (result.status === 'success') {
        setTimeout(() => window.location.replace(result.data), 700);
      }
    })
    .catch(error => {
      console.error('Error during authentication', error);
    });

export const logoutSuccess = () => ({
  type: types.USER_LOGOUT_SUCCESS
});

export const logout = () => dispatch =>
  userApi
    .logout()
    .then(result => {
      dispatch(logoutSuccess());
      setTimeout(() => {
        if (result.data.logoutUrl) {
          window.location.replace(result.data.logoutUrl);
        } else {
          window.location.reload();
        }
      }, 1000);
    })
    .catch(error => {});

export const authenticationSuccess = data => ({
  type: types.USER_AUTHENTICATION_SUCCESS,
  data
});

export const authentication = data => dispatch =>
  userApi
    .getUserToken(data)
    .then(token => {
      dispatch(authenticationSuccess(token));
      setTimeout(() => {
        navigate(token.data.usersLandingPage)(dispatch);
      }, 1000);
    })
    .catch(error => {
      console.error('Error during authentication / userApi.getUserToken', error);
    });

export const authenticationTestSuccess = data => ({
  type: types.USER_AUTHENTICATION_TEST_SUCCESS,
  data
});
export const authenticationNiam = params => dispatch =>
  userApi
    .getUserTokenNiam(params)
    .then(data => {
      dispatch(authenticationTestSuccess(data));
      const accessToken = get(data, 'data.accessToken');
      if (accessToken) localStorage.setItem('enento-niam-token', accessToken);

      const landingPage = get(data, 'data.usersLandingPage', '');
      if (landingPage) {
        setTimeout(() => {
          navigate(landingPage)(dispatch);
        }, 1000);
      }
      return true;
    })
    .catch(error => {
      console.error('Error during authenticationAjax / userApi.authenticationAjax', error);
      return false;
    });
export const authenticationAjax = (token, url) => dispatch =>
  userApi
    .getUserTokenTest(token, url)
    .then(data => {
      dispatch(authenticationTestSuccess(data));
      const landingPage = get(data, 'data.usersLandingPage', '');
      if (landingPage) {
        setTimeout(() => {
          navigate(landingPage)(dispatch);
        }, 1000);
      }
      return true;
    })
    .catch(error => {
      console.error('Error during authenticationAjax / userApi.authenticationAjax', error);
      return false;
    });

export const isLoggedIn = () =>
  userApi
    .isLoggedIn()
    .then(response => response)
    .catch(error => false);

export const isAtAdmin = () =>
  userApi
    .isAtAdmin()
    .then(response => response)
    .catch(error => false);

export const isCustomerAdminOrAtAdmin = () =>
  userApi
    .isCustomerAdminOrAtAdmin()
    .then(response => response)
    .catch(error => false);

export const isCustomerBasicUserOrCustomerAdminOrAtAdmin = () =>
  userApi
    .isCustomerBasicUserOrCustomerAdminOrAtAdmin()
    .then(response => response)
    .catch(error => false);

export const getUserForNaviSuccess = user => ({
  type: types.GET_USER_FOR_NAVI_SUCCESS,
  user
});

export const getUserForNavi = user => dispatch =>
  userApi
    .getUserSecurityLevel(user)
    .then(result => {
      dispatch(getUserForNaviSuccess(result));
    })
    .catch(error => {
      console.error('Error during getUserForNavi / userApi.getUserSecurityLevel', error);
    });
export const saveUserAnswer = answer => dispatch => userApi.saveUserAnswer(answer);

export const fetchTacSuccess = data => ({
  type: types.GET_TAC_FOR_FIRST_LOGIN_SUCCESS,
  data
});

export const fetchTac = lang => dispatch =>
  userApi
    .fetchTac(lang)
    .then(tac => {
      dispatch(fetchTacSuccess(tac));
    })
    .catch(error => {});

export const fetchFaqSuccess = data => ({
  type: types.GET_FAQS_SUCCESS,
  data
});

export const fetchFaq = lang => dispatch =>
  userApi
    .fetchFaq(lang)
    .then(faq => {
      dispatch(fetchFaqSuccess(faq));
    })
    .catch(error => {});

export const setFirstLoginDone = () => () => userApi.setFirstLoginDone();
