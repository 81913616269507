// import path from 'path';

import lodashGet from 'lodash/get';

import { Store } from '../store/configureStore';

import axios from 'axios';

const DEV_ENV = window.location.href.indexOf('dev.') >= 0;
const UC_ENV = window.location.href.indexOf('uc.se') >= 0;
const PINGURL =
  DEV_ENV || UC_ENV
    ? ''
    : window.location.href.indexOf('test.asiakastieto') >= 0
    ? ''
    : 'https://asiakastieto.fi/sopimusasiakas/ping';

export const get = (url, data = {}, responseType = null) => {
  const user = Store().getState().userState.user;
  
  return new Promise((resolve, reject) => {
    if (!url || !data) {
      reject('Parameters missing');
      return;
    }
    url = (url[0] === '/' ? url : `/${url}`)
    let headers = {};
    if (user) headers.user = user;

    headers = {
      ...headers,
      userspathname: window.location.hash
    };

    if (user && PINGURL) {
      axios
        .get(PINGURL, { withCredentials: true })
        .then(response => {})
        .catch(error => {});
    }

    let theUrl;
    if (DEV_ENV) {
      if (url.indexOf('b2c') >= 0) {
        theUrl = 'http://dev.asiakastieto.fi:5100' + url.replace('/b2c', '');
      } else {
        theUrl = 'http://dev.asiakastieto.fi:8080' + (url[0] === '/' ? url : `/${url}`);
      }
    } else {
      theUrl = '/api'+url//path.join('/api', url);
    }

    ////console.log(theUrl, data, headers, responseType);

    axios
      .get(theUrl, {
        params: data,
        headers,
        responseType
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (lodashGet(error, 'response.data.redirectUrl', '')) {
          window.location.href = lodashGet(error, 'response.data.redirectUrl', '');
        }
        reject(error);
      });
  });
};
export const post = (url, data, headers) => {
  const user = Store().getState().userState.user;

  return new Promise((resolve, reject) => {
    if (!url || !data) {
      reject('Parameters missing');
      return;
    }
    url = (url[0] === '/' ? url : `/${url}`)
    let setHeaders = {};
    if (user) setHeaders.user = user;

    setHeaders = {
      ...headers,
      ...setHeaders,
      userspathname: window.location.hash
    };

    if (user && PINGURL) {
      axios
        .get(PINGURL, { withCredentials: true })
        .then(response => {})
        .catch(error => {
          // headers.user = '';
        });
    }

    let theUrl;
    if (DEV_ENV) {
      if (url.indexOf('b2c') >= 0) {
        theUrl = 'http://dev.asiakastieto.fi:5100' + url.replace('/b2c', '');
      } else {
        theUrl = 'http://dev.asiakastieto.fi:8080' + (url[0] === '/' ? url : `/${url}`);
      }
    } else {
      theUrl = '/api'+url//path.join('/api', url);
    }

    axios
      .post(theUrl, data, {
        headers: setHeaders
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (lodashGet(error, 'response.data.redirectUrl', '')) {
          window.location.href = lodashGet(error, 'response.data.redirectUrl', '');
        }
        reject(error);
      });
  });
};

const AxiosService = { get, post };
export default AxiosService;
