import * as types from '../actions/action-types';

const initialState = {
  userAccesses: [],
  users: [],
  registers: []
};

const companyUsermanagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_ACCESSES_SUCCESS:
      return Object.assign({}, state, { userAccesses: action.userAccesses.data });

    case types.DELETE_USER_ACCESS_SUCCESS: {
      const removedId = action.userAccess.data[0].id;
      const modifiedUserAccesses = state.userAccesses.filter(
        userAccess => userAccess.id !== removedId
      );
      return Object.assign({}, state, { userAccesses: modifiedUserAccesses });
    }

    case types.CREATE_USER_ACCESS_SUCCESS: {
      const createdUserAccess = {
        id: action.userAccess.data.id,
        register: action.userAccess.data.register,
        user: action.userAccess.data.user
      };
      return Object.assign({}, state, { userAccesses: [...state.userAccesses, createdUserAccess] });
    }

    case types.GET_USERMANAGEMENT_USERS_SUCCESS:
      return Object.assign({}, state, { users: action.users.data });

    case types.UPDATE_USER_ACCESSROLE_SUCCESS: {
      const updatedUser = {
        id: action.user.data[0].id,
        name: action.user.data[0].name,
        accessRole: action.user.data[0].accessRole
      };
      let modifiedUsers = state.users;
      modifiedUsers = modifiedUsers.map(value => {
        let newValue = value;
        if (newValue.id === updatedUser.id) newValue = updatedUser;
        return newValue;
      });
      return Object.assign({}, state, { users: modifiedUsers });
    }

    case types.GET_COMPANY_REGISTERS_SUCCESS:
      return Object.assign({}, state, { registers: action.registers.data });

    default:
      // need this for default case
      return state;
  }
};

export default companyUsermanagementReducer;
