import * as types from '../actions/action-types';

const initialState = {
  orders: [],
  loading: true
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        orders: action.data || []
      });
    case types.TOGGLE_ORDER_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      });
    default:
      // need this for default case
      return state;
  }
};

export default orderReducer;
