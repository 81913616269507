import * as types from '../actions/action-types';

const initialState = {
  logs: [],
  logCount: -1
};

const logReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_GET_LOGS_SUCCESS:
      return Object.assign({}, state, {
        logs: [...action.logs.data.logs],
        logCount: action.logs.data.count
      });

    default:
      // need this for default case
      return state;
  }
};
export default logReducer;
